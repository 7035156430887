import React, { useState, useEffect } from "react";
import ButtonModal from "../../stories/Modals/ButtonModal";
import { Navigate, useNavigate } from "react-router-dom";
import SearchForm from "../../stories/Forms/SearchForm";
import { patientRecordsFields } from "../../stories/Forms/fields";
import useApis from "../../services/useAPI";
import { useAuth } from "../../components/AuthContext/AuthContext";
import formatData from "../../utils/utils";
import { XCircleFill } from "react-bootstrap-icons";
import Toaster from "../../stories/Toasts/Toaster";
import { mfaMessage } from "../../utils/constants";
import { searchBookingsUrl } from "../../utils/constants";
import { dateValidationSchema } from "../../stories/Forms/validationSchema";
import logo from "../../assets/images/mhc.png";
import img from "../../assets/images/notification.svg";
import { links } from "../../stories/Forms/fields";
import LoggedInLayout from "../../stories/LoggedInScreens/LoggedInLayout";

function PatientRecord() {
  // States for managing MFA, modals, and view states
  const [mfa, setMfa] = useState(
    JSON.parse(localStorage.getItem("mfa_enabled")) || false
  );
  const [show, setShow] = useState(!mfa && true);
  const [data, setData] = useState(false);

  // Hook for navigation
  const navigate = useNavigate();
  const auth = useAuth();

  const { callApi, resultPost, serverError, toggleShowError, showError } =
    useApis();

  // Handle hiding the MFA modal
  const handleHide = () => {
    localStorage.setItem("defered_mfa", true);
    setShow(false);
  };

  // Set the page title using the authentication context
  useEffect(() => {
    if (auth) {
      auth.setTitle("Patient Record");
    }
  }, [auth?.title]);

  // Navigate to enable OTP page
  const handleMFA = async () => {
    navigate("/enable-otp");
  };

  /**
   * Handles form submission for searching patient records.
   */
  const handleSubmit = async (data) => {
    const formattedData = formatData(data);
    setData(formattedData);
    await callApi(formattedData, searchBookingsUrl, "POST", {
      Authorization: `Bearer ${auth.accessToken}`,
      "Content-Type": "application/json",
    });
  };

  /**
   * Flattens a nested object into a single-level object with dot-separated keys.
   */
  const flattenObject = (obj, parentKey = "", res = {}) => {
    for (let key in obj) {
      if (
        obj[key] &&
        typeof obj[key] === "object" &&
        !Array.isArray(obj[key])
      ) {
        flattenObject(obj[key], `${parentKey}${key}.`, res);
      } else {
        res[`${parentKey}${key}`] = obj[key];
      }
    }
    return res;
  };

  return (
    <>
      <LoggedInLayout
        buttonLabel={"Logout"}
        img={img}
        isActivation={false}
        links={links}
        logo={logo}
        pageTitle={auth.title}
        userInitials={auth.userInitials}
      >
        {!serverError && resultPost && !resultPost.duplicate ? (
          <>
            <Navigate
              to="/patient-record/patient-details"
              state={{
                data: data,
                user: resultPost?.user_info,
                id: resultPost.bookings?.[0]?.phlebotomist_booking_id,
              }}
            />
          </>
        ) : !serverError && resultPost && resultPost.duplicate ? (
          <>
            <Navigate
              to="/patient-record/user-details"
              state={{
                data: data,
                user: resultPost?.user_info,
                isDuplicate: "true",
                id: resultPost.bookings?.[0]?.phlebotomist_booking_id,
              }}
            />
          </>
        ) : (
          <>
            {!mfa && !localStorage.getItem("defered_mfa") && (
              <ButtonModal
                heading={"Enable MFA"}
                message={
                  "Multi-factor Authentication (also known as Two Factor Authentication or 2FA) improves your account security by requiring a second means of authentication when logging in."
                }
                show={show}
                onHide={handleHide}
                onClick={handleMFA}
              />
            )}
            <SearchForm
              formError={"Please fill in at least 3 fields to submit the form"}
              fields={patientRecordsFields}
              validationSchema={dateValidationSchema}
              colStructure={4}
              fieldError={true}
              onSubmit={handleSubmit}
              description={
                "Enter the fields below to bring up the patient record"
              }
            />
            {serverError && (
              <Toaster
                icon={<XCircleFill />}
                header="Error"
                position={"top-end"}
                show={showError}
                body={serverError}
                onClose={toggleShowError}
              />
            )}
          </>
        )}
      </LoggedInLayout>
    </>
  );
}

export default PatientRecord;
