import React from "react";
import logo from "../assets/images/mhc.png";
import img from "../assets/images/notification.svg";
import { links } from "../stories/Forms/fields";
import LoggedInLayout from "../stories/LoggedInScreens/LoggedInLayout";

// Functional component for displaying a 404 Not Found page
const NotFound = () => {
  return (
    <>
      {/* Layout component for logged-in users with specific props */}
      <LoggedInLayout
        buttonLabel={"Logout"}
        img={img}
        isActivation={true}
        links={links}
        logo={logo}
        pageTitle={"Page not found"}
      >
        {/* Message displayed on the 404 page */}
        <h4>The page requested by you does not exist.</h4>
      </LoggedInLayout>
    </>
  );
};

export default NotFound;
