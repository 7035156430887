import React from "react";

const Invalid = (props) => {
  return (
    <div className="container server-error text-center">
      <strong>{props.message}</strong>
    </div>
  );
};

export default Invalid;
