import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { Button } from "../Buttons/Button";

function ConfirmationModal({
  heading,
  message,
  show,
  handleClose,
  handleConfirm,
  onCancelClick,
  hideButtons,

  ...props
}) {
  const handleCancelAndClose = () => {
    onCancelClick();
    handleClose();
  };

  const handleClick = () => {
    props.variantTwo === "danger" ? handleCancelAndClose() : handleConfirm();
  };

  return (
    <Modal show={show} onHide={handleClose} top>
      <Modal.Header closeButton>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        {!hideButtons && (
          <>
            <Button
              label={props.labelOne}
              onClick={
                props.vairantOne === "danger" ? handleClose : handleClick
              }
              size={"small"}
              variant={props.vairantOne}
            />
            <Button
              label={props.labelTwo}
              onClick={
                props.vairantOne === "danger" ? handleClick : handleClose
              }
              size={"small"}
              variant={props.variantTwo}
            />
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  heading: PropTypes.string,
  message: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  vairantOne: PropTypes.string,
  vairantTwo: PropTypes.string,
  labelOne: PropTypes.string,
  labelTwo: PropTypes.string,
  hideButtons: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  heading: "Confirmation",
};

export default ConfirmationModal;
