import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/main.scss";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./components/AuthContext/AuthContext";
import { FormProvider } from "./components/FormContext/FormContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <AuthProvider>
      <FormProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </FormProvider>
    </AuthProvider>
  </BrowserRouter>,
);

reportWebVitals();
