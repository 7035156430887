import React, { useEffect } from "react";
import DynamicForm from "../stories/Forms/DynamicForm";
import { confirmMfaFields } from "../stories/Forms/fields";
import useApis from "../services/useAPI";
import { enableMfaSchema } from "../stories/Forms/validationSchema";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../components/AuthContext/AuthContext";
import Toaster from "../stories/Toasts/Toaster";
import { verifyOtpUrl } from "../utils/constants";
import logo from "../assets/images/mhc.png";
import LoggedOutLayout from "../stories/LoggedOutScreens/LoggedOutLayout";

/*
 * Component for rendering the Confirm MFA page.
 * @returns {JSX.Element} - Rendered component.
 */

const ConfirmMFA = () => {
  // Hook to navigate programmatically
  const navigate = useNavigate();

  // API call hook configured for verifyOtpUrl endpoint with POST method
  const {
    callApi,
    resultPost,
    serverError,
    showSuccess,
    showError,
    toggleShowSuccess,
    toggleShowError,
  } = useApis(verifyOtpUrl, "POST");

  // Access authentication context
  const auth = useAuth();

  // Function to handle form submission
  const handleSubmit = async (data) => {
    // Add user_id to the submission data
    const postData = {
      ...data,
      user_id: auth.user || localStorage.getItem("user_id"),
    };

    // Call API to verify OTP
    await callApi(postData);

    // If resultPost is received, update localStorage and authentication context
    if (resultPost) {
      localStorage.setItem("isLoggedIn", true);
      auth.login(resultPost);
      localStorage.setItem("access_token", resultPost.access_token); // Store access token
      localStorage.setItem("refresh_token", resultPost.refresh_token); // Store refresh token
      localStorage.setItem("mfa_enabled", resultPost.mfa_enabled);

      // Redirect to patient record page after a short delay
      setTimeout(() => {
        navigate("/patient-record");
      }, 500);
    }
  };

  // Effect to handle authentication and navigation based on resultPost
  useEffect(() => {
    auth && auth.setTitle("Confirm MFA");
    if (resultPost) {
      localStorage.setItem("isLoggedIn", true);
      auth && auth.login(resultPost);
      localStorage.setItem("access_token", resultPost.access_token); // Store access token
      localStorage.setItem("refresh_token", resultPost.refresh_token); // Store refresh token
      localStorage.setItem("mfa_enabled", resultPost.mfa_enabled);

      setTimeout(() => {
        navigate("/patient-record");
      }, 500);
    }
  }, [resultPost, auth]);

  return (
    <LoggedOutLayout
      title={auth.title}
      className={
        auth.title === "Access dashboard"
          ? "login-bg login-page-container "
          : ""
      }
      logoSrc={logo}
    >
      <div>
        {/* Form component for confirming MFA */}
        <DynamicForm
          fields={confirmMfaFields}
          onSubmit={handleSubmit}
          validationSchema={enableMfaSchema}
          serverError={serverError}
        />
        {/* Success message toaster */}
        {showSuccess && (
          <Toaster
            position="top-end"
            show={showSuccess}
            onClose={toggleShowSuccess}
            header="Success"
            body="Logged in successfully!"
          />
        )}
        {/* Error message toaster */}
        {showError && (
          <Toaster
            position="top-end"
            show={showError}
            onClose={toggleShowError}
            header="Error"
            body={serverError}
          />
        )}
      </div>
    </LoggedOutLayout>
  );
};

export default ConfirmMFA;
