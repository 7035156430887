import React, { useEffect, useState } from "react";
import EvenGrid from "../../stories/Forms/EvenGrid";
import SuccessModal from "../../stories/Modals/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { activationSchema } from "../../stories/Forms/validationSchema";
import { activationFields } from "../../stories/Forms/fields";
import Invalid from "../../stories/utils/Invalid";
import useApis from "../../services/useAPI";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { activateUserUrl } from "../../utils/constants";
import { checkUserUrl } from "../../utils/constants";
import logo from "../../assets/images/mhc.png";
import img from "../../assets/images/notification.svg";
import { links } from "../../stories/Forms/fields";
import LoggedInLayout from "../../stories/LoggedInScreens/LoggedInLayout";

/**
 * Component for rendering the Activation page.
 * @returns {JSX.Element} - Rendered component.
 */
let user;
const Activation = () => {
  const [activationStr, setActivationStr] = useState("");
  const [error, setError] = useState();
  const [isActivation, setActivation] = useState(false);
  const navigate = useNavigate();

  const { id } = useParams();
  const auth = useAuth();

  const {
    callApi,
    setUrl,
    setMethod,
    serverError,
    resultGet,
    resultPost,
    modalShow,
    setModalShow,
  } = useApis(`${checkUserUrl}${id}`, "GET");

  // Fetch activation string on component mount or when 'id' changes
  useEffect(() => {
    auth.setTitle("My Account");
    const fetchActivationStr = async () => {
      await callApi();
    };

    fetchActivationStr();
  }, [id, auth.title]);

  // Update activation string if 'resultGet' changes
  useEffect(() => {
    auth.setTitle("My Account");

    if (resultGet) {
      user = resultGet.activation_str;
      setActivationStr(resultGet.activation_str);
    }
  }, [resultGet]);

  useEffect(() => {
    // Check for conditional rendering of LoggedInLayout.
    window.location.pathname.includes("/my-account")
      ? setActivation(true)
      : setActivation(false);
  }, [isActivation]);

  // Handle form submission
  const handleSubmit = async (data) => {
    setError("");
    setUrl(activateUserUrl);
    setMethod("POST");

    const postData = {
      ...data,
      occupation: data.occupation.toLowerCase(),
      activation_string: id,
      email: user.email,
      organisation: user.organisation,
    };

    await callApi(postData, activateUserUrl, "POST");
    if (!serverError) {
      setModalShow(true);
    }
  };

  const handleRedirect = () => {
    navigate("/patient-record");
  };

  return (
    <>
      <LoggedInLayout
        buttonLabel={"Logout"}
        img={img}
        isActivation={isActivation}
        links={links}
        logo={logo}
        pageTitle={auth.title}
      >
        {!activationStr ? (
          <Invalid message={serverError} />
        ) : (
          <>
            <p className="form-title">
              Enter the fields below to create account
            </p>
            <EvenGrid
              fields={activationFields}
              serverError={serverError || error}
              onSubmit={handleSubmit}
              askConfirmation={true}
              data={activationStr || user}
              validationSchema={activationSchema}
            />
            {resultPost && (
              <SuccessModal
                label={"Continue"}
                variant={"danger"}
                show={modalShow}
                redirect={handleRedirect}
                onHide={() => setModalShow(false)}
                href="/login"
              />
            )}
          </>
        )}
      </LoggedInLayout>
    </>
  );
};

export default Activation;
