import React, { createContext, useState, useContext } from 'react';

const FormContext = createContext();

export function FormProvider({ children }) {
  const [blurredValues, setBlurredValues] = useState({});

  const updateBlurredValue = (name, value) => {
    setBlurredValues((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <FormContext.Provider value={{ blurredValues, updateBlurredValue }}>
      {children}
    </FormContext.Provider>
  );
}

export function useFormContext() {
  return useContext(FormContext);
}
