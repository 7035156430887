import { useState, useCallback, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../components/AuthContext/AuthContext";
import { logoutUrl, refreshTokenUrl } from "../utils/constants";

const useApis = (initialUrl, initialMethod = "GET") => {
  const [serverError, setServerError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resultGet, setResultGet] = useState(null);
  const [resultPost, setResultPost] = useState(null);
  const [url, setUrl] = useState(initialUrl);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [message, setMessage] = useState("");
  const [refreshFailed, setRefreshFailed] = useState(false);

  const isRefreshing = useRef(false); // Tracks if refresh is in progress
  const pendingRequests = useRef([]); // Tracks pending requests

  const navigate = useNavigate();
  const auth = useAuth();
  const [method, setMethod] = useState(initialMethod);

  // Function to check if error is related to token issues
  const isTokenError = (errorMessage) => {
    return (
      errorMessage === "Token is missing" ||
      errorMessage === "Token has expired"
    );
  };

  // Function to refresh access token
  const refreshAccessToken = useCallback(async () => {
    if (isRefreshing.current || refreshFailed) return;

    isRefreshing.current = true;
    try {
      const response = await axios.post(refreshTokenUrl, {
        refresh: auth.refreshToken,
      });

      const newAccessToken = response.data.access_token;

      // Updates the access token in AuthContext and localStorage
      auth.setAccessToken(newAccessToken);
      localStorage.setItem("access_token", newAccessToken);

      isRefreshing.current = false;
      setRefreshFailed(false);

      // Retries all pending requests with the new access token
      pendingRequests.current.forEach((callback) => callback());
      pendingRequests.current = [];
    } catch (error) {
      isRefreshing.current = false;
      setRefreshFailed(true);
      auth.logout();
      navigate("/login");
      throw error; // Re-throws the error after logging out
    }
  }, [auth, navigate, refreshFailed]);

  // Function to call an API
  const callApi = async (
    data = null,
    customUrl = null,
    customMethod = null,
    headers = {},
  ) => {
    setServerError("");
    setIsLoading(true);

    const requestUrl = customUrl || url;
    const requestMethod = customMethod || method;

    const filterUndefinedValues = (obj) => {
      return Object.fromEntries(
        Object.entries(obj).filter(([_, value]) => value !== undefined),
      );
    };

    const sanitizedData = data ? filterUndefinedValues(data) : null;

    const config = {
      url: requestUrl,
      method: requestMethod,
      data: sanitizedData,
      headers: {
        ...headers,
        Authorization: `Bearer ${auth.accessToken}`, // Ensures the latest token is used
      },
    };

    const request = async () => {
      try {
        const response = await axios(config);

        if (requestMethod !== "GET") {
          if (sanitizedData && Object.keys(sanitizedData).length === 0) {
            setMessage("No data provided for the request.");
          } else {
            response.data.data && setResultPost(response.data.data);
            response.data.message &&
              setMessage({ postMessage: response.data.message });
          }
        } else if (requestMethod === "GET") {
          response.data.data && setResultGet(response.data.data);
          response.data.message &&
            setMessage({ getMessage: response.data.message });
        }

        if (response.status === 200) {
          setShowSuccess(true);
          if (requestMethod !== "GET") setModalShow(true);
        } else {
          setShowError(true);
          if (requestMethod !== "GET") setModalShow(true);
          setServerError(`An unknown error occurred`);
        }
      } catch (error) {
        setShowError(true);
        const errorMessage =
          error.response?.data?.message ||
          error.response?.data?.error ||
          "An unknown error occurred";

        if (isTokenError(errorMessage)) {
          setServerError(""); // Clear previous server error
        } else {
          setServerError(errorMessage);
        }

        if (
          error.response?.status === 401 &&
          error.response?.data?.token_expired
        ) {
          if (!refreshFailed) {
            pendingRequests.current.push(async () => {
              try {
                await refreshAccessToken();
                await callApi(data, customUrl, customMethod, headers);
              } catch (refreshError) {
                auth.logout();
                navigate("/login");
              }
            });

            if (!isRefreshing.current) {
              await refreshAccessToken();
              await callApi(data, customUrl, customMethod, headers);
            }
          }
        } else if (error.response?.data?.unauthorized) {
          setTimeout(() => {
            auth.logout();
            callApi(null, logoutUrl, "POST");
            navigate("/login");
          }, 3000);
        } else if (
          error.response?.status === 401 &&
          error.response?.data?.session_expired
        ) {
          setServerError("Session has expired. Please log in again");
          setTimeout(() => {
            auth.logout();
            callApi(null, logoutUrl, "POST");
            navigate("/login");
          }, 3000);
        }
      } finally {
        setIsLoading(false);
      }
    };

    return request();
  };

  return {
    callApi,
    setUrl,
    setMethod,
    serverError,
    isLoading,
    resultGet,
    resultPost,
    showSuccess,
    showError,
    modalShow,
    message,
    setModalShow,
    setShowSuccess,
    setShowError,
    toggleShowSuccess: () => setShowSuccess(!showSuccess),
    toggleShowError: () => setShowError(!showError),
  };
};

export default useApis;
