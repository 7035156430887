import React, { useState, useEffect } from "react";
import { Primary } from "../../stories/Buttons/Button.stories";
import { format_dt_age, statusMapping } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import DuplicateIdTable from "../../stories/Tables/DuplicateIdTable";
import img from "../../assets/images/notification.svg";
import logo from "../../assets/images/mhc.png";
import { links } from "../../stories/Forms/fields";
import LoggedInLayout from "../../stories/LoggedInScreens/LoggedInLayout";
import formatData from "../../utils/utils";
import { useAuth } from "../../components/AuthContext/AuthContext";
import useApis from "../../services/useAPI";
import { searchBookingsUrl } from "../../utils/constants";

/**
 * UserDetails component displays details of a patient record.
 * It includes a search form, a table for displaying modified bookings, and a back button.
 * @param {Object} props - Component props.
 * @param {Array} props.bookings - Modified bookings data.
 * @param {Object} props.user - User data.
 * @returns {JSX.Element|null} - Rendered component or null if user data is not available.
 */

function UserDetails({ user }) {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const [data, setData] = useState();

  const { callApi, resultPost, serverError } = useApis();

  /**
   * Handles form submission for searching patient records.
   */
  const handleSubmit = async (data) => {
    const formattedData = data && formatData(data);

    await callApi(formattedData, searchBookingsUrl, "POST", {
      Authorization: `Bearer ${auth.accessToken}`,
      "Content-Type": "application/json",
    });
  };

  useEffect(() => {
    location.state?.data && handleSubmit(location.state?.data);
  }, []);

  /* Modify user data to format date of birth */
  const userData = location.state?.user
    ? {
        ...location.state.user,
        dob: format_dt_age(user.dob),
      }
    : null;

  const handleBackClick = () => {
    navigate(-1);
  };

  /**
   * Handles form submission when a duplicate ID is detected.
   */
  const handleSubmitDuplicateId = async (data) => {
    const { status, dob, ...dataWithoutStatus } = data;
    setData(dataWithoutStatus);
    await callApi(dataWithoutStatus, searchBookingsUrl, "POST", {
      Authorization: `Bearer ${auth.accessToken}`,
      "Content-Type": "application/json",
    });
  };

  useEffect(() => {
    if (!resultPost?.dulicate) {
      resultPost?.bookings &&
        navigate("/patient-record/patient-details", {
          state: { resultPost, user: userData },
        });
    }
  }, [data, resultPost]);

  /**
   * Flattens a nested object into a single-level object with dot-separated keys.
   */
  const flattenObject = (obj, parentKey = "", res = {}) => {
    for (let key in obj) {
      if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        flattenObject(obj[key], parentKey + key + ".", res);
      } else {
        res[parentKey + key] = obj[key];
      }
    }
    return res;
  };

  /**
   * Modifies duplicate ID booking data to prepare it for display in the UI.
   */
  const modifyDuplicateIdBookings = (users) => {
    return users.map((user) => {
      const bookingStatus =
        statusMapping[user.status?.replace(/-/g, "_")] || {};

      /* Determine if hover should be disabled */
      const hover =
        ["registered", "created", "activating"].indexOf(
          user.status?.toLowerCase()
        ) === -1;

      return {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        dob: user.dob && user.dob,
        status: {
          component: (
            <Primary
              variant={bookingStatus.variant}
              label={bookingStatus.label}
              size="small"
              hover={hover} // Pass the hover prop here
            />
          ),
        },
      };
    });
  };

  /**
   * Flattens and modifies duplicate ID booking data for display.
   */
  const flattenedBookingsDuplicateId =
    resultPost?.users?.map((booking) => booking && flattenObject(booking)) ||
    [];
  const modifiedBookingsDuplicateId = flattenedBookingsDuplicateId.length
    ? modifyDuplicateIdBookings(flattenedBookingsDuplicateId)
    : [];

  return (
    <LoggedInLayout
      buttonLabel={"Logout"}
      img={img}
      isActivation={false}
      links={links}
      logo={logo}
      pageTitle={auth.title}
      userInitials={auth.userInitials}
    >
      {serverError && <span className="server-error">{serverError}</span>}
      {resultPost?.duplicate && (
        <div className="patient-details-page">
          <div className="contianer">
            <div className="row">
              <div className="col-md-12">
                <h3 className="server-error mb-3">
                  More than one patient are registered with these details -
                  Please select from table below
                </h3>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <DuplicateIdTable
                  data={modifiedBookingsDuplicateId}
                  sort={false}
                  search={false}
                  pagination={true}
                  onSubmit={handleSubmitDuplicateId}
                />
                <div className="container p-0">
                  <div className="row">
                    <div className="col-md-12">
                      <Primary
                        variant="outline"
                        className="ms-auto d-block"
                        size="small"
                        label="Back"
                        onClick={() => handleBackClick()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </LoggedInLayout>
  );
}

export default UserDetails;
