import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginForm from "../views/LoginForm";
import Index from "../views/Index";
import AppointmentRecord from "../views/AppointmentRecords/AppointmentRecord";
import Accounts from "../views/Accounts/Accounts";
import Resources from "../views/Resources";
import Activation from "../views/Activation/Activation";
import ConfirmMFA from "../views/ConfirmMFA";
import ForgotPassword from "../views/ForgotPassword";
import EmailConfirmation from "../views/EmailConfirmation";
import EnableMFA from "../views/EnableMFA";
import ResetPassword from "../views/ResetPassword";
import PrivateRoute from "../components/AuthContext/PrivateRoute";
import PatientRecord from "../views/PatientRecords/PatientRecord";
import PatientDetails from "../views/PatientRecords/PatientDetails";
import UserDetails from "../views/PatientRecords/UserDetails";
import EnterBarcode from "../views/PatientRecords/EnterBarcode";
import ConfirmDetails from "../views/PatientRecords/ConfirmDetails";
import ChangePassword from "../views/ChangePassword";
import ConfirmationSteps from "../views/PatientRecords/ConfirmationSteps";
import AppointmentConfirmation from "../views/PatientRecords/AppointmentConfirmation";
import NotFound from "../components/NotFound";

export default () => {
  return (
    <Routes>
      <Route element={<Index />} path="/" />
      <Route element={<LoginForm />} path="/login" />
      <Route
        element={<PrivateRoute element={PatientRecord} />}
        path="/patient-record"
      />
      <Route
        element={<PrivateRoute element={PatientDetails} />}
        path="/patient-record/patient-details"
      />
      <Route
        element={<PrivateRoute element={UserDetails} />}
        path="/patient-record/user-details"
      />
      <Route
        element={<PrivateRoute element={AppointmentRecord} />}
        path="/appointments-record"
      />
      <Route element={<PrivateRoute element={Accounts} />} path="/account" />
      <Route element={<PrivateRoute element={Resources} />} path="/resources" />
      <Route element={<Activation />} path="/my-account/:id" />
      <Route element={<ConfirmMFA />} path="/enable-webauthn" />
      <Route element={<ForgotPassword />} path="/forgot-password" />
      <Route element={<EmailConfirmation />} path="/email-confirmation" />
      <Route
        element={<PrivateRoute element={EnableMFA} />}
        path="/enable-otp"
      />
      <Route element={<ResetPassword />} path="/reset-password/:uuid/:id" />
      <Route
        element={<PrivateRoute element={EnterBarcode} />}
        path="/enter-barcode/:id"
      />
      <Route
        element={<PrivateRoute element={ConfirmDetails} />}
        path="/test-confirmation/:id"
      />
      <Route
        element={<PrivateRoute element={AppointmentConfirmation} />}
        path="/appointment-confirmation/:id"
      />
      <Route
        element={<PrivateRoute element={ConfirmationSteps} />}
        path="/confirmation-steps/:id"
      />
      <Route
        element={<PrivateRoute element={ChangePassword} />}
        path="/change-password"
      />
      <Route element={<NotFound />} path="*" />
    </Routes>
  );
};
