import React from 'react';
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css';



const StepCard = ({ number, image, body, isSelected, onClick }) => {
  return (
    <div className='col-md-2 test-confirmation-col'>
    <Card className={`test-confirmation-card ${isSelected && 'active-card'}`} onClick={onClick}>
      <div className={`step-number ${isSelected && 'active-card'}`}>{number}</div>
      <Card.Body>
        <div className='step-img'>
          <img alt={`confirmation step icon ${number}`} src={image}/>
        </div>       
        <p>{body}</p>
      </Card.Body>
    </Card>
    </div>
  );
};

export default StepCard;
