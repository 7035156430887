import React, { useEffect, useState } from "react";
import StepCard from "../../stories/Cards/StepCard";
import step1 from "../../assets/images/shakey icon.svg";
import step2 from "../../assets/images/step2.svg";
import step2_2 from "../../assets/images/Group.svg";
import step3_2 from "../../assets/images/label sample icon.svg";
import step3 from "../../assets/images/step3.svg";
import step4 from "../../assets/images/step4.svg";
import step5 from "../../assets/images/step5.svg";
import step6 from "../../assets/images/step6.svg";
import { Button } from "../../stories/Buttons/Button";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import useApis from "../../services/useAPI";
import SuccessModal from "../../stories/Modals/Modal";
import logo from "../../assets/images/mhc.png";
import img from "../../assets/images/notification.svg";
import { links } from "../../stories/Forms/fields";
import LoggedInLayout from "../../stories/LoggedInScreens/LoggedInLayout";
import { stepsOne, stepsTwo } from "../../utils/constants";

// Declare steps variable to be assigned later
let steps;

const ConfirmationSteps = () => {
  const location = useLocation();

  // Extract secondary barcode from location state
  const { secondary_barcode } = location.state?.state || {};

  const [selectedSteps, setSelectedSteps] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  // Extract id from URL parameters
  const { id } = useParams();
  const navigate = useNavigate();

  // Define steps with conditional images based on the presence of secondary_barcode
  steps = [
    {
      number: 1,
      image: step1,
      body: (secondary_barcode && stepsTwo.Box1) || stepsOne.Box1,
    },
    {
      number: 2,
      image: (secondary_barcode && step2_2) || step2,
      body: (secondary_barcode && stepsTwo.Box2) || stepsOne.Box2,
    },
    {
      number: 3,
      image: (secondary_barcode && step3_2) || step3,
      body: (secondary_barcode && stepsTwo.Box3) || stepsOne.Box3,
    },
    {
      number: 4,
      image: step4,
      body: (secondary_barcode && stepsTwo.Box4) || stepsOne.Box4,
    },
    {
      number: 5,
      image: step5,
      body: (secondary_barcode && stepsTwo.Box5) || stepsOne.Box5,
    },
    {
      number: 6,
      image: step6,
      body: (secondary_barcode && stepsTwo.Box6) || stepsOne.Box6,
    },
  ];

  const postUrl = `/api/phlebotomists-portal/register-appointment/${id}/`;
  const { callApi, resultPost, message, modalShow, setModalShow } = useApis();

  const auth = useAuth();

  // Set the title when the component mounts or when certain dependencies change
  useEffect(() => {
    auth.setTitle("CONFIRMATION OF TEST REGISTRATION");
  }, [auth, message, navigate, resultPost]);

  // Handle step card click to toggle selection
  const handleCardClick = (number) => {
    setSelectedSteps((prevSelectedSteps) =>
      prevSelectedSteps.includes(number)
        ? prevSelectedSteps.filter((step) => step !== number)
        : [...prevSelectedSteps, number],
    );
    setErrorMessage("");
  };

  // Handle confirm button click to validate step completion and make API call
  const handleConfirm = () => {
    if (selectedSteps.length !== steps.length) {
      setErrorMessage(
        "Please ensure all steps are completed before proceeding",
      );
    } else {
      callApi(null, postUrl, "POST", {
        Authorization: `Bearer ${auth.accessToken}`,
        "Content-Type": "application/json",
      });
    }
  };

  const handleRedirect = () => {
    navigate("/patient-record");
  };

  return (
    <>
      <LoggedInLayout
        buttonLabel={"Logout"}
        img={img}
        isActivation={false}
        links={links}
        logo={logo}
        pageTitle={auth.title}
        userInitials={auth.userInitials}
      >
        <div className="test-confirmation-page">
          <div className="container p-0">
            <div className="row">
              <div className="col-md-12">
                <h2>For {secondary_barcode ? "two" : "one"} tube test:</h2>
                <h3>
                  Before returning the sample,{" "}
                  <u>please click to confirm the following steps:</u>
                </h3>
              </div>
            </div>
          </div>

          <div className="container p-0">
            <div className="row">
              {steps?.map((step) => (
                <StepCard
                  key={step.number}
                  number={step.number}
                  image={step.image}
                  body={step.body}
                  isSelected={selectedSteps.includes(step.number)}
                  onClick={() => handleCardClick(step.number)}
                />
              ))}
              {errorMessage && (
                <div className="server-error">{errorMessage}</div>
              )}
            </div>

            {selectedSteps?.length === steps?.length && (
              <Button
                className="mt-4 mr-4 float-end"
                size={"small"}
                variant={"secondary"}
                label={"Confirm"}
                onClick={handleConfirm}
              />
            )}
          </div>
        </div>
        {modalShow && (
          <SuccessModal
            href={"/patient-record"}
            message={"SUCCESS: This test has been successfully registered"}
            handleClose={""}
            show={modalShow}
            variant={"secondary"}
            label={"Return to Patient Record"}
            redirect={handleRedirect}
            onHide={() => {
              setModalShow(false);
              setTimeout(() => {
                navigate("/patient-record");
              }, 500);
            }}
          />
        )}
      </LoggedInLayout>
    </>
  );
};

export default ConfirmationSteps;
