import React from "react";
import Router from "./Router";

export default () => {
  return (
    <>
      <Router />
    </>
  );
};
