import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

/**
 * Manages restricted access to the views
 * @param {Object} props - The input parameters.
 * @param {Element} props.element - The HTML element to be used.
 * @param {Object} props.rest - The rest of the parameters.
 */

const PrivateRoute = ({ element: Element, ...rest }) => {

  const { isAuthenticated } = useAuth();
  
  // Restrict user from accessing authenticated views
  return JSON.parse(isAuthenticated) ? (
    <Element {...rest} />
  ) : (
    <Navigate to="/login" />
 );
};

export default PrivateRoute;
