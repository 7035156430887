import React from "react";
import PropTypes from "prop-types";
import { Card, Container } from "react-bootstrap";

/**
 * A layout component with a title, logo, and children content.
 * @param {Object} props - The props for the Layout component.
 * @param {string} props.title - The title of the layout.
 * @param {string} props.logoSrc - The source URL for the logo image.
 * @param {ReactNode} props.children - The content to be rendered inside the layout.
 * @param {string} props.className - Additional CSS class for the layout container.
 */

const LoggedOutLayout = ({ title, logoSrc, children, className }) => {
  /* Combine the provided className with the default layout container class */
  const combinedClassName = `${className ? className + " " : ""}layout-container forgot-password-container login-form`;

  return (
    <Container className={combinedClassName}>
      <Card>
        <Card.Img alt="mhc-logo" className="logo" src={logoSrc} />
        <Card.Body>
          <h2>{title}</h2>
          {children}
        </Card.Body>
      </Card>
    </Container>
  );
};

/* PropTypes for Layout */
LoggedOutLayout.propTypes = {
  /**
   * Title of the layout
   */
  title: PropTypes.string,
  /**
   * Source of the logo image
   */
  logoSrc: PropTypes.string,
  /**
   * Additional class name(s) for styling
   */
  className: PropTypes.string,
  /**
   * Content to be rendered inside the layout
   */
  children: PropTypes.node,
};

/* Default props for Layout */
LoggedOutLayout.defaultProps = {
  title: "Log in",
};

export default LoggedOutLayout;
