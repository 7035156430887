import React, { useEffect } from "react";
import SearchForm from "../../stories/Forms/SearchForm";
import logo from "../../assets/images/mhc.png";
import img from "../../assets/images/notification.svg";
import { links } from "../../stories/Forms/fields";
import LoggedInLayout from "../../stories/LoggedInScreens/LoggedInLayout";
import { searchFormFields } from "../../stories/Forms/fields";
import Table from "../../stories/Tables/Table";
import formatData, {
  formatReportedDate,
  statusMapping,
} from "../../utils/utils";
import { Primary } from "../../stories/Buttons/Button.stories";
import { format_dt_age } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../components/AuthContext/AuthContext";
import useApis from "../../services/useAPI";
import { searchBookingsUrl } from "../../utils/constants";

/**
 * PatientDetails component displays details of a patient record.
 * It includes a search form, a table for displaying modified bookings, and a back button.
 * @param {Object} props - Component props.
 * @param {Array} props.bookings - Modified bookings data.
 * @param {Object} props.user - User data.
 * @returns {JSX.Element|null} - Rendered component or null if user data is not available.
 */

function PatientDetails({ bookings, user, setPatientDetailsRoute }) {
  const auth = useAuth();
  auth.setTitle("PATIENT RECORD");
  const location = useLocation();
  const navigate = useNavigate();
  const { callApi, resultPost, serverError } = useApis();

  /* Modify user data to format date of birth */
  const userData = location.state.user
    ? {
        ...location.state.user,
        dob: format_dt_age(location.state.user?.dob),
      }
    : location.state.resultpost?.user_info
      ? {
          ...location.state.resultpost.user_info,
          dob: format_dt_age(location.state.resultpost.user_info?.dob),
        }
      : null;

  /**
   * Handles form submission for searching patient records.
   */
  const handleSubmit = async (data) => {
    const formattedData = data && formatData(data);
    await callApi(formattedData, searchBookingsUrl, "POST", {
      Authorization: `Bearer ${auth.accessToken}`,
      "Content-Type": "application/json",
    });
  };

  useEffect(() => {
    location.state.data &&
      handleSubmit(
        location.state.data
          ? location.state.data
          : location.state.resultPost.bookings[0]
      );
  }, [location.state]);

  //Kept for further reference just in case if above condition breaks.
  // useEffect(() => {
  //   const bookingData = location.state?.data || location.state?.resultPost?.bookings?.[0];
  //   if (bookingData) {
  //     handleSubmit(bookingData);
  //   }
  // }, [location.state]);

  /**
   * Flattens a nested object into a single-level object with dot-separated keys.
   */
  const flattenObject = (obj, parentKey = "", res = {}) => {
    for (let key in obj) {
      if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        flattenObject(obj[key], parentKey + key + ".", res);
      } else {
        res[parentKey + key] = obj[key];
      }
    }
    return res;
  };

  // Modify booking data for display
  const modifyBookings = (bookings) => {
    return bookings.map((booking, index) => {
      const bookingStatus =
        statusMapping[booking.status?.replace(/-/g, "_")] || {};
      const isRegistered = booking.status === "registered";
      const isCreated = booking.status === "created";
      const isActivating =
        booking.status === "activating" &&
        booking.phlebotomist_booking_id !== "";

      /* Determine if hover should be disabled */
      const hover = isRegistered || isCreated || isActivating;

      return {
        id: index + 1,
        testType: booking.test_type,
        sampleType: booking.sample_type,
        reportedDate:
          booking.reported_date && formatReportedDate(booking.reported_date),
        doctor: booking.reviewer_name ? booking.reviewer_name : " ",
        status: {
          component: (
            <Primary
              variant={bookingStatus.variant}
              label={bookingStatus.label}
              size="small"
              hover={hover}
              onClick={(e) => {
                if (!isRegistered && !isCreated && !isActivating) {
                  e.preventDefault();
                } else if (booking.status === "registered") {
                  navigate(
                    `/test-confirmation/${booking.phlebotomist_booking_id}`
                  );
                } else {
                  // for activating and created status redirecting directly to appointment confirmation
                  navigate(
                    `/appointment-confirmation/${booking.phlebotomist_booking_id}`
                  );
                }
              }}
            />
          ),
        },
      };
    });
  };

  // Flatten and modify booking data
  const flattenedBookings = location.state.resultPost?.bookings
    ? location.state.resultPost?.bookings.map((booking) =>
        flattenObject(booking)
      )
    : resultPost?.bookings?.map((booking) => flattenObject(booking)) || [];

  const modifiedBookings = flattenedBookings.length
    ? modifyBookings(flattenedBookings)
    : [];

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <LoggedInLayout
      buttonLabel={"Logout"}
      img={img}
      isActivation={false}
      links={links}
      logo={logo}
      pageTitle={auth.title}
      userInitials={auth.userInitials}
    >
      <div className="patient-details-page">
        <div className="contianer">
          {serverError && <span className="server-error">{serverError}</span>}
          <div className="row">
            <div className="col-md-12">
              <SearchForm
                fields={searchFormFields}
                data={
                  location.state.resultPost?.user_info
                    ? location.state.resultPost?.user_info
                    : userData
                }
                colStructure={3}
                description="Enter the fields below to bring up the patient record"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <hr className="divider" />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Table
                data={modifiedBookings}
                sort={true}
                search={true}
                pagination={true}
              />
              <div className="container p-0">
                <div className="row">
                  <div className="col-md-12">
                    <Primary
                      variant="outline"
                      className="ms-auto d-block"
                      size="small"
                      label="Back"
                      onClick={() => handleBackClick()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoggedInLayout>
  );
}

export default PatientDetails;
