import React, { useEffect } from "react";
import { useAuth } from "../../components/AuthContext/AuthContext";

function AppointmentRecord() {

  const auth = useAuth()
  
  useEffect(()=>{
    auth.setTitle('Appointment Record')
  },[auth.title])

  return <div>AppointmentRecord</div>;
}

export default AppointmentRecord;
