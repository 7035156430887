import * as yup from "yup";
import "yup-phone-lite";

// Regular expression to ensure password doesn't contain sequences of three or more consecutive letters or numbers
const sequence =
  /^(?!.*(?:012|123|234|345|456|567|678|789|890|098|987|876|765|654|543|432|321|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz)).*$/;

// Validation rules for password
const passwordValidation = yup
  .string()
  .required("Password is required")
  .matches(/^[^\s]+$/, "Password must not contain any spaces")
  .matches(
    /^(?!.*(\w)\1{2,})/,
    "Password must not contain repetitive characters",
  )
  .matches(
    sequence,
    "Password should not contain sequences of three or more consecutive letters or numbers.",
  )
  .matches(
    /^(?=.*[A-Z])/,
    "Password must contain at least one uppercase letter",
  )
  .matches(
    /^(?=.*[a-z])/,
    "Password must contain at least one lowercase letter",
  )
  .matches(
    /^(?=.*[@$!%*?&])/,
    "Password must contain at least one special character",
  )
  .matches(/^(?=.*\d)/, "Password must contain at least one number")
  .min(8, "Minimum 8 characters");

// Schema for login validation
export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email("Please enter a valid email"),
  password: yup.string().required("Password is required"),
});

// Schema for forgot password validation
export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email("Please enter a valid email"),
});

// Schema for reset password validation
export const resetPasswordSchema = yup.object().shape({
  password: passwordValidation,
  confirmpassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

// Schema for activation validation
export const activationSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .matches(/^[^\s]+$/, "Password must not contain any spaces")
    .matches(
      /^(?!.*(\w)\1{2,})/,
      "Password must not contain repetitive characters",
    )
    .matches(
      sequence,
      "Password should not contain consecutive letters or numbers.",
    )
    .matches(/^(?=.*[A-Z])/, "One uppercase character")
    .matches(/^(?=.*[a-z])/, "One lowercase character")
    .matches(/^(?=.*[@$!%*?&])/, "One special character")
    .matches(/^(?=.*\d)/, "One number")
    .min(8, "Mininum 8 characters"),
  occupation: yup.string().required("Please ensure you enter your occupation."),
  mobile: yup
    .string()
    .max(11, "Mobile number cannot exceed 11 digits")
    .phone("GB", "Please enter a valid UK phone number"),
  first_name: yup
    .string()
    .matches(/^[a-zA-Z]+$/, "Firstname cannot contain a number")
    .required("Please ensure you enter at least your first name"),
  last_name: yup
    .string()
    .matches(/^[a-zA-Z]+$/, "Lastname cannot contain a number")
    .required("Please ensure you enter at least your last name"),
});

// Schema for enabling multi-factor authentication
export const enableMfaSchema = yup.object().shape({
  otp: yup.number().required("Please ensure you enter your auth code"),
});

// Schema for account management validation
export const accountSchema = yup.object().shape({
  first_name: yup
    .string()
    .matches(
      /^[a-zA-Z]+$/,
      "First name cannot contain numbers, spaces, or special characters",
    )
    .matches(/^[^\s]+$/, "First name cannot contain spaces")
    .required("Please ensure you enter at least your first name"),
  last_name: yup
    .string()
    .matches(/^[^\s]+$/, "Last name cannot contain spaces")
    .required("Please ensure you enter at least your last name."),
  occupation: yup
    .string()
    .matches(/^[^\s]+$/, "Occupation cannot contain spaces")
    .required("Please enter your occupation."),
  phone_number_primary: yup
    .string()
    .max(11)
    .phone("GB", "Please enter a valid UK phone number"),
});

// Schema for changing passwords
export const changePasswordSchema = yup.object().shape({
  current_password: yup.string().required("Current password is required"),
  new_password: passwordValidation
    .required("New password is required")
    .notOneOf(
      [yup.ref("current_password"), null],
      "New password cannot be the same as the current password",
    ),
  new_password: passwordValidation
    .required("New password is required")
    .notOneOf(
      [yup.ref("current_password"), null],
      "New password cannot be the same as the current password",
    ),
  confirm_password: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("new_password"), null], "Passwords must match"),
});

// Schema for entering barcode
export const enterBarcodeSchema = yup.object().shape({
  primary_barcode: yup.string().required("Primary barcode is required"),
});

// Schema for validating dates
export const dateValidationSchema = yup.object().shape({
  dob: yup.date().test("valid-date", "Invalid date format", (value) => {
    if (!value) return true;
    const year = new Date(value).getFullYear();
    return year >= 1000 && year <= 9999;
  }),
});

// Schema for barcode rejction flow
export const rejectValidationSchema = yup.object().shape({
  notes: yup.string().required("Notes is required"),
  rejection_reason: yup.string().required("Reason for rejection is required"),
});
