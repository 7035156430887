import React from "react";
import LoginPage from "./LoginPage";

function Index() {
  return (
    <>
      <LoginPage />
    </>
  );
}

export default Index;
