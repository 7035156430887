import React from "react";
import { Image, Nav } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Logout from "../../Logout";
import { useLocation } from "react-router-dom";

const Sidebar = ({ links, logo, isActivation }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  /**
   * Determines if the current path matches specific patterns for the "/patient-record" route.
   */
  const isActivePath = (path) => {
    // Define the patterns that should be matched for the "/patient-record" route
    const patterns = [
      /\/patient-record(\/|$)/, // Matches /patient-record or /patient-record/anything
      /\/test-confirmation\/\d+/,
      /\/enter-barcode\/\d+/,
      /\/appointment-confirmation\/\d+/,
      /\/confirmation-steps\/\d+/,
    ];

    // Check if the current path matches any of the patterns
    return patterns.some((pattern) => pattern.test(currentPath));
  };

  /**
   * Determines if a given item should be marked as active based on the current path.
   */
  const isActive = (itemHref) => {
    return (
      currentPath === itemHref ||
      (itemHref === "/patient-record" && isActivePath(itemHref))
    );
  };

  return (
    <div className="sidebar">
      <Navbar className="bg-body-tertiary" expand="lg">
        <Container className="d-inline p-0">
          <Navbar.Brand>
            <a href="/patient-record">
              <Image
                alt="My Health Checked"
                className="d-block m-auto"
                src={logo}
              />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="flex-column">
              {!isActivation &&
                links?.map((item, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link active={isActive(item.href)} href={item.href}>
                      <div className="nav-icon">
                        <Image
                          alt={item.alt}
                          className="icon"
                          src={item.icon}
                        />
                      </div>
                      {item.text}
                    </Nav.Link>
                  </Nav.Item>
                ))}
            </Nav>
          </Navbar.Collapse>

          {!isActivation && <Logout />}
        </Container>
      </Navbar>
    </div>
  );
};

Sidebar.propTypes = {
  /**
   * Logo source
   */
  logo: PropTypes.string.isRequired,
  /**
   * Array of sidebar links
   */
  sidebarLinks: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Text for the link
       */
      text: PropTypes.string.isRequired,
      /**
       * Icon source for the link
       */
      icon: PropTypes.string.isRequired,
      /**
       * Alternate text for the icon
       */
      alt: PropTypes.string.isRequired,
    })
  ),
  /**
   * Content to be rendered inside the layout
   */
  children: PropTypes.node,
  /**
   * Image source
   */
  img: PropTypes.string,
  /**
   * Title of the page
   */
  pageTitle: PropTypes.string,
};

export default Sidebar;
