import React, { useEffect } from "react";
import { useAuth } from "../components/AuthContext/AuthContext";
import LoggedInLayout from "../stories/LoggedInScreens/LoggedInLayout";
import logo from "../assets/images/mhc.png";
import img from "../assets/images/notification.svg";
import { links } from "../stories/Forms/fields";

function Resources() {
  const auth = useAuth();

  // Set the page title to "Resources" when the component mounts
  useEffect(() => {
    auth.setTitle("Resources");
  });
  return (
    <LoggedInLayout
      buttonLabel={"Logout"}
      img={img}
      isActivation={false}
      links={links}
      logo={logo}
      pageTitle={auth.title}
      userInitials={auth.userInitials}
    >
      <div className="row justify-content-center pt-4">
        <div className="col-md-10">
          <h2>Help:</h2>
          <p>
            To Learn about Storybooks:{" "}
            <a href="https://storybook.js.org/tutorials/intro-to-storybook/react/en/get-started/">
              Visit here
            </a>{" "}
            This will give you a brief idea of the concept
          </p>
          <p>
            To Learn about React Bootstrap:{" "}
            <a href="https://react-bootstrap.netlify.app/docs/getting-started/introduction/">
              Visit here
            </a>{" "}
            This will give you a brief idea of the concept
          </p>
          <p>
            To Learn about Storybooks:{" "}
            <a href="https://storybook.js.org/tutorials/intro-to-storybook/react/en/get-started/">
              Visit here
            </a>{" "}
            This will give you a brief idea of the concept
          </p>
          <p>
            To Learn about Storybooks:{" "}
            <a href="https://storybook.js.org/tutorials/intro-to-storybook/react/en/get-started/">
              Visit here
            </a>{" "}
            This will give you a brief idea of the concept
          </p>
        </div>
      </div>
    </LoggedInLayout>
  );
}

export default Resources;
