import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Button } from "../Buttons/Button";
import PropTypes from "prop-types";
import { Formik } from "formik";
import FormInput from "./FormInput";
import { InfoCircleFill } from "react-bootstrap-icons";
import classNames from "classnames";

/**
 * SearchForm component for rendering a search form with dynamic fields.
 *
 * @param {Object} props - The props object.
 * @param {Object} props.fields - The fields configuration object.
 * @param {number} props.colStructure - The column structure for form layout.
 */

function SearchForm(props) {
  const [showMessage, setShowMessage] = useState(false);
  const [formErrorState, setFormErrorState] = useState("");

  useEffect(() => {
    // Handle showing and hiding error messages
    if (showMessage && props.formError) {
      setFormErrorState(props.formError);
      const timer = setTimeout(() => {
        setShowMessage(false);
        setFormErrorState("");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showMessage, props.formError]);

  // Generate initial values for the form based on fields and data
  const generateInitialValues = (fields, data) => {
    const initialValues = {};
    fields.forEach((field) => {
      if (field.type !== "button" && field.type !== "submit") {
        initialValues[field.name] =
          data && data[field.name] !== undefined ? data[field.name] : "";
      }
    });
    return initialValues;
  };

  const initialValues = generateInitialValues(props.fields, props.data);
  const {
    className,
    colStructure,
    description,
    fieldError,
    fields,
    helptext,
    helptextClass,
    isEditMode,
    onBackClick,
    onBlur,
    onCancelClick,
    onEditClick,
    onRegister,
    onRejectClick,
    onSubmit,
    useLocalStorage,
  } = props;

  return (
    <div className={classNames("patient-record-form", className)}>
      {/* Display error message if needed */}
      {showMessage && formErrorState && (
        <span className="server-error" variant="danger">
          <InfoCircleFill />
          {formErrorState}
        </span>
      )}

      {/* Render description if provided */}
      {description && <h2>{description}</h2>}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          delete values.undefined;
          if (fieldError) {
            // Check if at least 3 fields are filled
            const filledCount = fields.filter(
              (field) => values[field.name],
            ).length;
            if (filledCount >= 3) {
              onSubmit && onSubmit(values);
            } else {
              setShowMessage(true);
            }
          } else {
            onSubmit && onSubmit(values);
          }
          resetForm();
        }}
        validationSchema={props.validationSchema}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {({
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          touched,
          values,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              {fields.map((field, index) => (
                <React.Fragment key={index}>
                  {field.type === "barcode-text" && (
                    <div className="col-md-12">
                      <span className="underline-text">
                        1. <span className="danger-text">Before</span> drawing
                        blood
                      </span>{" "}
                      enter the kit barcode number
                    </div>
                  )}
                  <Col
                    className={
                      field.type === "button" || field.type === "submit"
                        ? "ms-auto"
                        : field.type === "date"
                          ? "birth-date-field"
                          : field.type === "time"
                            ? "time-field"
                            : field.type === "select"
                              ? "attempts-field"
                              : ""
                    }
                    md={
                      field.btnGroup
                        ? field.name === "Notes"
                          ? 6
                          : undefined
                        : field.colSize || colStructure
                    }
                  >
                    <Form.Group>
                      <div
                        className={`${
                          field.name === "secondary_barcode"
                            ? "barcode-group secondary-barcode-group"
                            : " "
                        }`}
                      >
                        {field.type !== "button" &&
                        field.type !== "submit" &&
                        field.type !== "barcode-text" &&
                        !field.btnGroup ? (
                          <FormInput
                            as={field.as}
                            className={`form-group ${field.className} ${
                              field.type === "date" && field.value
                                ? "placeholder-text"
                                : ""
                            }`}
                            error={errors[field.name]}
                            helpText={
                              field.name === "primary_barcode"
                                ? helptext || ""
                                : field.helpText || ""
                            }
                            helptextClass={helptextClass}
                            helpTextAbove={field.helpTextAbove}
                            image={field.FormFieldImage}
                            key={field.name}
                            label={field.label}
                            name={field.name}
                            onBlur={
                              field.name === "primary_barcode"
                                ? (e) => {
                                    onBlur && onBlur(e);
                                  }
                                : handleBlur
                            }
                            onChange={(e) => {
                              handleChange(e);
                              if (field.type === "select") {
                                setFieldValue(field.name, e.target.value);
                              }
                            }}
                            onSelectChange={props.onSelectChange}
                            options={field.options || []}
                            placeholder={field.placeholder}
                            readOnly={!isEditMode && field.readOnly}
                            required={field.required}
                            storeName={props.storeName}
                            touched={touched[field.name]}
                            type={field.type}
                            value={
                              field.type === "text-area"
                                ? props.values
                                : values[field.name]
                            }
                            useLocalStorage={useLocalStorage}
                          />
                        ) : isEditMode && field.type !== "barcode-text" ? (
                          <>
                            <Col md={6}>
                              <Button
                                className="buttons float-right"
                                onClick={onCancelClick}
                                variant="danger"
                              >
                                Cancel
                              </Button>
                            </Col>
                            <Col md={6}>
                              <Button
                                className="buttons float-right"
                                onClick={handleSubmit}
                                variant="secondary"
                              >
                                Save
                              </Button>
                            </Col>
                          </>
                        ) : field.btnGroup &&
                          field.btnGroup.length &&
                          field.type !== "barcode-text" >= 1 ? (
                          <div className="confirm-details-buttons">
                            {field.btnGroup.map((button) => (
                              <Button
                                className={classNames(
                                  "buttons float-right ",
                                  button.className,
                                )}
                                disabled={
                                  button.label === "Reject"
                                    ? props.isRejectDisabled
                                    : false
                                }
                                helpTextAbove={props.helpTextAbove}
                                iconOrder={button.iconOrder}
                                label={button.label}
                                onClick={
                                  button.name === "back" ||
                                  button.label === "Cancel"
                                    ? onBackClick
                                    : button.label === "EDIT"
                                      ? onEditClick
                                      : button.label === "Reject"
                                        ? onRejectClick
                                        : onRegister
                                }
                                size={button.size}
                                svg={button.icon}
                                type={button.type}
                                variant={button.variant}
                              >
                                {button.label}
                              </Button>
                            ))}
                          </div>
                        ) : (
                          field.type !== "barcode-text" && (
                            <Button
                              className={classNames(
                                "buttons float-right ",
                                field.className,
                              )}
                              disabled={
                                field.label === "REJECT"
                                  ? props.isRejectDisabled
                                  : false
                              }
                              helpTextAbove={props.helpTextAbove}
                              iconOrder={field.iconOrder}
                              label={field.label}
                              onClick={
                                field.name === "back"
                                  ? onBackClick
                                  : field.label === "EDIT"
                                    ? onEditClick
                                    : field.label === "REJECT"
                                      ? onRejectClick
                                      : null
                              }
                              size={field.size}
                              svg={field.icon}
                              type={field.type}
                              variant={field.variant}
                            >
                              {field.label}
                            </Button>
                          )
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                  {field.hrAfter && (
                    <Col md={12}>
                      <hr className="mt-0 mb-4" />
                      {field.textAfter && (
                        <h2 className="date-time-attempts-title help-text-above">
                          <>{props.textAfter}</>
                        </h2>
                      )}
                    </Col>
                  )}
                </React.Fragment>
              ))}
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}

SearchForm.propTypes = {
  validationSchema: PropTypes.objectOf(PropTypes.any),
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      controlId: PropTypes.number,
      label: PropTypes.string,
      type: PropTypes.string,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      readOnly: PropTypes.bool,
      required: PropTypes.bool,
      variant: PropTypes.string,
      className: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        }),
      ),
      helpText: PropTypes.string,
      helpTextAbove: PropTypes.string,
      FormFieldImage: PropTypes.node,
      colSize: PropTypes.number,
      hrAfter: PropTypes.bool,
      textAfter: PropTypes.string,
    }),
  ).isRequired,
  colStructure: PropTypes.number.isRequired,
  formError: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  description: PropTypes.string,
  data: PropTypes.object,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  onBackClick: PropTypes.func,
  helptext: PropTypes.string,
  buttonGroup: PropTypes.bool,
  isEditMode: PropTypes.bool,
  onEditClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  onRejectClick: PropTypes.func,
  isRejected: PropTypes.bool,
  storeName: PropTypes.string,
  onSelectChange: PropTypes.string,
  isRejectDisabled: PropTypes.bool,
  values: PropTypes.string,
  useLocalStorage: PropTypes.bool,
};

export default SearchForm;
