export const logoutConfirmation = "Are you sure, you want to log out?";

export const dirtyFromConfirmation =
    "Are you sure you want to leave without saving changes? Any unsaved modifications will be lost.";

export const sessionWarningMessage =
    "Your session is about to expire due to inactivity!";

export const logoutUrl =
    "/api/phlebotomists-portal/logout-user/";

export const changePasswordUrl =
    "/api/phlebotomists-portal/change-password/";

export const verifyOtpUrl =
    "/api/phlebotomists-portal/verify-otp-phlebotomist-test/";

export const enableMFAUrl =
    "/api/phlebotomists-portal/phlebotomist-enable-mfa/";

export const phlebotomistVerifyOtpUrl =
  "/api/phlebotomists-portal/phlebotomist-verify-otp/";

export const forgotPasswordUrl =
    "/api/phlebotomists-portal/phlebotmoist-forgot-password/";

export const loginUrl =
    "/api/phlebotomists-portal/login-user/";

export const resetPasswordUrl =
  "/api/phlebotomists-portal/phlebotomist-reset-password";

export const searchBookingsUrl =
    "/api/phlebotomists-portal/search-bookings/";

export const postUrlBarcode =
    "/api/phlebotomists-portal/validate-barcode/";

export const activateUserUrl =
    "/api/phlebotomists-portal/activate-user";

export const checkUserUrl =
  "/api/phlebotomists-portal/check-user/?activation_str=";

export const incompleteAppointmentUrl =
  "/api/phlebotomists-portal/incomplete-appointments/";

export const editUserUrl =
    "/api/phlebotomists-portal/edit-user/";

export const refreshTokenUrl = "/api/phlebotomists-portal/refresh-token/";

export const stepsOne = {
  Box1: "Ensure the tube is securely closed and gently mix by inverting it five times",
  Box2: "Label the sample with the correct barcode label in a vertical position",
  Box3: "Fill out the patient information on the return form on the specimen transport bag",
  Box4: "Place into the transport bag, seal it and place inside the outer box",
  Box5: "Place the outer box of the collection into the return postal bag and seal it",
  Box6: "Store the sample in a refrigerator at a temperature 2-8C until it is collected by the courier",
};

export const stepsTwo = {
  Box1: "Ensure the tube(s) are securely closed and gently mix by inverting the gold tube five times and lavender tube 8-10 times in total",
  Box2: "Label the sample(s) with the correct barcode label(s) in a vertical position",
  Box3: "Fill out the patient information on the return form on the specimen transport bag",
  Box4: "Place into the transport bag, seal it and place inside the outer box",
  Box5: "Place the outer box of the collection into the return postal bag and seal it",
  Box6: "Store the sample(s) in a refrigerator at a temperature 2-8C until it is collected by the courier",
};
