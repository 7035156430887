import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const Toaster = (props) => {

  const [autoHide, setAutoHide] = useState();

  useEffect(()=>{
    setTimeout(()=>{
      setAutoHide('d-none')
    }, 3000)
  },[autoHide])

  return (
    <div className={autoHide}>
      <ToastContainer role={props.role} aria-roledescription={props.role} className='position-fixed' position={props.position}>
        <Toast show={props.show} onClose={props.onClose}>
          <Toast.Header>
            <img src="" className="rounded me-2" alt="" />
            <strong className="me-auto">{props.header}</strong>
          </Toast.Header>
          <Toast.Body>{props.body}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

/* Define PropTypes for the Toaster component */
Toaster.propTypes = {
  /**
   * Content for the header
   */
  header: PropTypes.string.isRequired,
  /**
   * Content for the profile dropdown
   */
  body: PropTypes.string.isRequired,
  /**
   * Define position of the Toaster
   */
  position: PropTypes.string,
};

export default Toaster;
