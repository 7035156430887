import React from "react";
import { Button } from "./Button";
import doctorBag from "../../assets/images/hcp-icon.svg";
import { fn } from "@storybook/test";

export default {
  title: "Components/Buttons",
  component: Button,
  /* Center the component in Storybook UI */
  parameters: {
    layout: "centered",
  },
  /* control to set image in buttons */
  argTypes: {
    svg: {
      control: { type: "file" },
    },
  },
  args: { onClick: fn() },
  tags: ["autodocs"],
};

const Template = (args) => <Button {...args} />;

/* Story for the primary button */
export const Primary = Template.bind({});
Primary.args = {
  variant: "primary",
  label: "Login",
  size: "medium",
};

/* Story for the EventButton */
export const EventButton = Template.bind({});
EventButton.args = {
  variant: "secondary",
  label: "Confirm",
  onClick: () => console.log("Button Clicked Here"),
  size: "medium",
};

/* Story for the LinkButton */
export const LinkButton = Template.bind({});
LinkButton.args = {
  variant: "danger",
  label: "Reject",
  href: "http://localhost:3000/",
  target: true,
  size: "medium",
};

/* Story for the outlined button */
export const Outline = Template.bind({});
Outline.args = {
  variant: "outline",
  label: "Logout",
  size: "medium",
};

/* Story for the Oval button */
export const OvalButton = Template.bind({});
OvalButton.args = {
  variant: "olive",
  label: "Admin",
  size: "medium",
  svg: doctorBag,
};

/* Story for the Profile button */
export const ProfileButton = Template.bind({});
ProfileButton.args = {
  variant: "gray",
  label: "DN",
};

/* Story for the White button */
export const WhiteButton = Template.bind({});
WhiteButton.args = {
  variant: "white button",
  label: "white",
};
