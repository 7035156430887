import React, { useEffect, useState } from "react";
import useApis from "../services/useAPI";
import { Spinner } from "react-bootstrap";
import { enableMfaFields } from "../stories/Forms/fields";
import DynamicForm from "../stories/Forms/DynamicForm";
import { useAuth } from "../components/AuthContext/AuthContext";
import { enableMfaSchema } from "../stories/Forms/validationSchema";
import { useNavigate } from "react-router-dom";
import Toaster from "../stories/Toasts/Toaster";
import { enableMFAUrl } from "../utils/constants";
import { phlebotomistVerifyOtpUrl } from "../utils/constants";
import LoggedInLayout from "../stories/LoggedInScreens/LoggedInLayout";
import logo from "../assets/images/mhc.png";
import img from "../assets/images/notification.svg";
import { links } from "../stories/Forms/fields";

// Main component for enabling Multi-Factor Authentication (MFA)
const EnableMFA = () => {
  // Custom hook for API calls and state management
  const {
    callApi,
    resultPost,
    serverError,
    resultGet,
    message,
    showSuccess,
    isLoading,
    toggleShowSuccess,
  } = useApis(enableMFAUrl, "GET");

  const navigate = useNavigate();
  const auth = useAuth();

  // To get QR code for user
  useEffect(() => {
    auth.setTitle("Enable MFA");

    callApi("", enableMFAUrl, "GET");
  }, [auth.title]);

  // Handle form submission and OTP verification
  const handleSubmit = async (data) => {
    const postData = {
      ...data,
      user_id: auth.user,
    };

    await callApi(postData, phlebotomistVerifyOtpUrl, "POST", {
      Authorization: `Bearer ${auth.accessToken}`,
      "Content-Type": "application/json",
    });
  };

  // Manage post-submission success and redirection
  useEffect(() => {
    if (message) {
      localStorage.setItem("isLoggedIn", true);
      auth.login(resultPost);
      localStorage.setItem("access_token", resultPost.access_token); // Store access token
      localStorage.setItem("refresh_token", resultPost.refresh_token); // Store refresh token
      localStorage.setItem("mfa_enabled", resultPost.mfa_enabled);
      setTimeout(() => {
        navigate("/patient-record");
      }, 1000);
    }
  }, [resultPost]);


  return (
    <LoggedInLayout
      buttonLabel={"Logout"}
      img={img}
      isActivation={false}
      links={links}
      logo={logo}
      pageTitle={auth.title}
      userInitials={auth.userInitials}
    >
      <div className="row justify-content-center pt-4">
        <div className="col-md-6">
          <h2>Enable App Multi-factor Authentication (MFA)</h2>
          <div className="text-muted">
            <p>
              Multi-factor Authentication (also known as Two Factor
              authentication or 2FA) improves your account security by requiring
              a second means of authentication when logging in.
            </p>
            <p>Scan the QR code below using one of these app:</p>
            <ul>
              <li>
                <b>Google Authenticator</b> for{" "}
                <Link platform="Android" app="ga" /> or{" "}
                <Link platform="iPhone" app="ga" />
              </li>
              <li>
                <b>Authy</b> for <Link platform="Android" app="authy" /> or{" "}
                <Link platform="iPhone" app="authy" />
              </li>
            </ul>
          </div>
          <div className="d-flex justify-content-center pt-2">
            {isLoading ? <Spinner /> : <img
              src={`data:image/png;base64,${resultGet && resultGet.qr_code_image}`}
              className="rounded qr-image border"
              alt="MFA QR code"
              width="300"
              height="300"
            />}
          </div>
          <DynamicForm
            fields={enableMfaFields}
            onSubmit={handleSubmit}
            validationSchema={enableMfaSchema}
            serverError={serverError}
          />
          {resultPost && (
            <Toaster
              position="top-end"
              role="alert"
              aria-roledescription="alert"
              show={showSuccess}
              onClose={toggleShowSuccess}
              header="Success"
              body="MFA enabled successfully!"
            />
          )}
        </div>
      </div>
    </LoggedInLayout>
  );
};

export default EnableMFA;

// Link component for app download links
const Link = ({ platform, app }) => (
  <a href={link[platform][app]} target="_blank" rel="noopener noreferrer">
    {platform}
  </a>
);

// Links for different MFA apps
const link = {
  Android: {
    ga: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_GB&gl=US",
    authy:
      "https://play.google.com/store/apps/details?id=com.authy.authy&hl=en_GB&gl=US",
  },
  iPhone: {
    ga: "https://apps.apple.com/us/app/google-authenticator/id388497605",
    authy: "https://apps.apple.com/us/app/twilio-authy/id494168017",
  },
};
