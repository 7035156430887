import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../components/AuthContext/AuthContext";
import { Primary } from "../stories/Buttons/Button.stories";
import logo from "../assets/images/mhc.png";
import LoggedOutLayout from "../stories/LoggedOutScreens/LoggedOutLayout";
import Toaster from "../stories/Toasts/Toaster";
function EmailConfirmation() {
  // Access authentication context
  const auth = useAuth();
  // Hook to get location object, including state from previous navigation
  const location = useLocation();

  // Set page title when component mounts
  useEffect(() => {
    auth.setTitle("Please check your inbox");
  }, [auth.title]);

  return (
    <>
      <LoggedOutLayout
        title={auth.title}
        className={
          auth.title === "Access dashboard"
            ? "login-bg login-page-container "
            : ""
        }
        logoSrc={logo}
      >
        {/* Display success toaster if email is available in location state */}
        {location.state?.email && (
          <Toaster
            position="top-end"
            show={"showSuccess"}
            onClose={"toggleShowSuccess"}
            header="Success"
            body="Please check your inbox, an email has been sent to you!"
          />
        )}
        <p>
          Instructions for resetting your password have been sent to{" "}
          {`${auth.email || location.state?.email}`}. Please follow the steps
          outlined in the email to create a new password.{" "}
          <span className="ageo-medium">
            For your security, the link will only be valid for 1 hour.
          </span>{" "}
        </p>
        <p>
          For further questions, please contact us at{" "}
          <a href="mailto:care@myhealthchecked.com">care@myhealthchecked.com</a>
        </p>
        <div className="form-group">
          <Primary label={"Close window"} href="/login"></Primary>
        </div>
      </LoggedOutLayout>
    </>
  );
}

export default EmailConfirmation;
