import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useAuth } from "./components/AuthContext/AuthContext";
import useApis from "./services/useAPI";
import Toaster from "./stories/Toasts/Toaster";
import { Button } from "./stories/Buttons/Button";
import ConfirmationModal from "./stories/Modals/ConfirmationModal";
import { logoutUrl } from "./utils/constants";

/**
 * Handles Logout funcionality.
 * Calls logout API and clears local storage
 * @returns
 */
function Logout() {
  const auth = useAuth();

  const [modalShow, setModalShow] = useState(false);

  // API hook initialisation
  const {
    callApi,
    serverError,
    message,
    showSuccess,
    toggleShowSuccess,
    toggleShowError,
    showError,
  } = useApis(logoutUrl, "POST");

  const handleClick = async () => {
    await callApi();
  };

  useEffect(() => {
    if (message) {
      auth.logout();
    }
  }, [message]);

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleModalConfirm = async () => {
    setModalShow(false);
    await handleClick();
  };

  return (
    <>
      <Container className="text-center">
        <Button
          className="logout-btn"
          label="Logout"
          onClick={() => {
            setModalShow(true);
          }}
          variant="outline"
        />
        {message?.postMessage && (
          <Toaster
            body="Logged out successfully!"
            header="Success"
            onClose={toggleShowSuccess}
            position="top-end"
            show={showSuccess}
          />
        )}
        {serverError && (
          <Toaster
            body={serverError}
            header="Error"
            onClose={toggleShowError}
            position="top-end"
            show={showError}
          />
        )}
      </Container>
      <ConfirmationModal
        handleClose={handleModalClose}
        handleConfirm={handleModalConfirm}
        heading="Confirm Logout"
        labelOne={"Cancel"}
        labelTwo={"Logout"}
        message="Are you sure you want to log out?"
        show={modalShow}
        vairantOne={"danger"}
        variantTwo={"primary"}
      />
    </>
  );
}

export default Logout;
