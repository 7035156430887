import React, { useEffect } from "react";
import DynamicForm from "../stories/Forms/DynamicForm";
import { forgotPasswordFields } from "../stories/Forms/fields";
import { Container } from "react-bootstrap";
import { forgotPasswordSchema } from "../stories/Forms/validationSchema";
import { useAuth } from "../components/AuthContext/AuthContext";
import useApis from "../services/useAPI";
import EmailConfirmation from "./EmailConfirmation";
import { useNavigate } from "react-router-dom";
import Toaster from "../stories/Toasts/Toaster";
import { forgotPasswordUrl } from "../utils/constants";
import logo from "../assets/images/mhc.png";
import LoggedOutLayout from "../stories/LoggedOutScreens/LoggedOutLayout";

let email;
// Global variable to control form visibility
let hideForm = false;

function ForgotPassword() {
  const auth = useAuth();
  const navigate = useNavigate();

  // Custom hook for API calls and state management
  const {
    callApi,
    serverError,
    message,
    showSuccess,
    toggleShowSuccess,
  } = useApis(forgotPasswordUrl, "POST"); // Custom hook for API calls.

  // Handle form submission and make API call
  const handleSubmit = async (data) => {
    email = data.email.toLowerCase();
    const payload = { email: data.email.toLowerCase() };
    callApi(payload);
  };

  // Effect to handle email setting, form hiding, and navigation
  useEffect(() => {
    auth.setEmail(email);
    if (message) {
      auth.logout();
      setTimeout(() => {
        hideForm = true;
        navigate("/email-confirmation");
      }, 1000);
    }
    auth.setTitle("Forgot password");
  }, [auth.title, auth.email, message, navigate]);

  return (
    <>
      <LoggedOutLayout
        title={auth.title}
        className={
          auth.title === "Access dashboard"
            ? "login-bg login-page-container "
            : ""
        }
        logoSrc={logo}
      >
        {/* Conditionally render form based on hideForm state */}
        {!hideForm && (
          <Container>
            <p>
              Enter the email address associated with your account & we'll email
              you a password reset link
            </p>
            <DynamicForm
              fields={forgotPasswordFields}
              onSubmit={handleSubmit}
              serverError={serverError}
              validationSchema={forgotPasswordSchema}
            />
          </Container>
        )}
      </LoggedOutLayout>
      {/* Conditionally render toaster and email confirmation if email and message are present */}
      {auth.email && message && (
        <>
          <Toaster
            position="top-end"
            show={showSuccess}
            onClose={toggleShowSuccess}
            header="Success"
            body="Please check your inbox, an email has been sent to you!"
          />
          <EmailConfirmation email={auth.email} />
        </>
      )}
    </>
  );
}

export default ForgotPassword;
