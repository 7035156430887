import React, { useEffect, useState } from "react";
import Page from "./Page";
import { useAuth } from "./components/AuthContext/AuthContext";

function App() {
  let isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));

  const [isActivation, setActivation] = useState(false);

  const auth = useAuth();

  useEffect(() => {
    // Check for conditional rendering of LoggedInLayout.
    window.location.pathname.includes("/my-account")
      ? setActivation(true)
      : setActivation(false);
  }, [isActivation, isLoggedIn, auth.isAuthenticated]);

  return (
    <>
      <Page />
    </>
  );
}
export default App;
