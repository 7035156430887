import React, { useEffect } from "react";
import { OvalButton } from "../stories/Buttons/Button.stories";
import { useAuth } from "../components/AuthContext/AuthContext";
import LoggedOutLayout from "../stories/LoggedOutScreens/LoggedOutLayout";
import doctorBag from "../assets/images/hcp-icon.svg";
import admin from "../assets/images/admin-icon.svg";
import logo from "../assets/images/mhc.png";

/**
 * Component for rendering the login page.
 * @returns {JSX.Element} - Rendered component.
 */

function LoginPage() {
const auth = useAuth();

  // Function to determine the admin URL based on the current host
  const getAdminUrl = () => {
    const url = window.location.host.includes("phlebotomist-")
      ? window.location.host.split("-")[1]
      : "http://localhost:8008/admin";
    const adminUrl = window.location.host.includes("phlebotomist-")
      ? window.location.host.includes("staging")
        ? "https://admin-staging.myhealthchecked.com/admin/"
        : `https://${url}:444/admin/`
      : url;
    return adminUrl;
  };

  // Set the title of the page
  useEffect(() => {
    auth.setTitle("Access dashboard");
  }, [auth.title]);

  return (
    <>
      <LoggedOutLayout
        title={auth.title}
        className={
          auth.title === "Access dashboard"
            ? "login-bg login-page-container "
            : ""
        }
        logoSrc={logo}
      >
        <OvalButton
          variant={"olive"}
          label={"Health Professional"}
          size={"medium"}
          svg={doctorBag}
          href="/login"
        />
        <OvalButton
          variant={"offwhite"}
          label={"Admin"}
          size={"medium"}
          svg={admin}
          href={
            process.env.REACT_APP_DJANGO_ADMIN_ENDPOINT || `${getAdminUrl()}`
          }
        />
      </LoggedOutLayout>
    </>
  );
}

export default LoginPage;
