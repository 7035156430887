import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import { Formik } from "formik";
import PromptIfDirty from "./PromptIfDirty";
import { Button } from "../Buttons/Button";
import FormInput from "./FormInput";
import PropTypes from "prop-types";
import { InfoCircleFill } from "react-bootstrap-icons";

/** Renders an evenly structured Form in two columns
 * @param {Array} fields - The fields to be rendered in the form.
 * @param {Object} validationSchema - The schema used for form validation.
 * @param {Function} onSubmit - The function to call when the form is submitted.
 * @param {Object} data - The initial data for the form.
 * @param {string} serverError - The error message from the server, if any.
 * @returns {JSX.Element} The form component.
 */

const EventGrid = ({
  fields,
  validationSchema,
  onSubmit,
  askConfirmation,
  data,
  serverError,
}) => {
  // initial values will be generated dynamically based on fields
  // if any prepopulated data is present that will be added as initial value
  const generateInitialValues = (fields, data) => {
    const initialValues = {};
    fields.rows.forEach((row) => {
      row.forEach((field) => {
        if (field.type !== "button" && field.type !== "submit") {
          initialValues[field.name] = data ? data[field.name] : "";
        }
      });
    });
    return initialValues;
  };

  return (
    <Formik
      initialValues={generateInitialValues(fields, data)}
      onSubmit={async (values, { resetForm }) => {
        delete values.undefined; // removes any undefined object from data object
        await onSubmit(values);
        resetForm();
      }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <Form
          className="text-left form-wrapper"
          noValidate
          onSubmit={handleSubmit}
        >
          <span className="server-error mb-2">
            {serverError && (
              <>
                <InfoCircleFill />
                {serverError}
              </>
            )}
          </span>
          {askConfirmation && <PromptIfDirty />}
          {fields.rows.map((row, rowIndex) => (
            <Row key={rowIndex}>
              {row.map((col, colIndex) => (
                <Col key={colIndex} md={6}>
                  {col.type !== "button" && (
                    <FormInput
                      className={"inputs"}
                      error={errors[col.name]}
                      onChange={handleChange}
                      touched={touched[col.name]}
                      value={values[col.name]}
                      {...col}
                    />
                  )}
                  {col.type === "button" && (
                    <div className="form-group">
                      <Form.Label className="labels">&nbsp;</Form.Label>
                      <Button
                        className="buttons custom-button"
                        label={col.label}
                        type="submit"
                        variant={col.variant}
                      />
                    </div>
                  )}
                </Col>
              ))}
            </Row>
          ))}
        </Form>
      )}
    </Formik>
  );
};

EventGrid.propTypes = {
  fields: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object,
  serverError: PropTypes.string,
};

export default EventGrid;
