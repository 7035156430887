import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "./Header";
import Sidebar from "./Sidebar";
import PropTypes from "prop-types";

/**
 * Component for rendering the layout of a logged-in user.
 * @param {Object} props - Props for the LoggedInLayout component.
 * @param {string} props.logo - Logo source.
 * @param {Array} props.sidebarLinks - Array of sidebar links.
 * @param {Node} props.children - Content to be rendered inside the layout.
 * @param {string} props.img - Image source.
 * @param {string} props.buttonLabel - Label for the button.
 * @param {string} props.pageTitle - Title of the page.
 * @returns {JSX.Element} - Rendered component.
 */
const LoggedInLayout = ({
  logo,
  links,
  children,
  img,
  userInitials,
  buttonLabel,
  pageTitle,
  isActivation,
}) => {
  return (
    <>
      {/* Main container */}
      <Container className="vh-100" fluid>
        {/* Row for layout */}
        <Row className="h-100">
          {/* Sidebar column */}
          <Col className="sidebar-container">
            {/* Sidebar component */}
            <Sidebar
              buttonLabel={buttonLabel}
              isActivation={isActivation}
              links={links}
              logo={logo}
            />
          </Col>
          {/* Main content column */}
          <Col className="main-content" lg={10} md={9} xs={9}>
            {/* Header component */}
            <Header
              img={img}
              isActivation={isActivation}
              title={pageTitle}
              userInitials={userInitials}
            />
            {/* Main content */}
            <main>{children}</main>
          </Col>
        </Row>
      </Container>
    </>
  );
};

LoggedInLayout.propTypes = {
  /**
   * Logo source
   */
  logo: PropTypes.string.isRequired,
  /**
   * Array of sidebar links
   */
  sidebarLinks: PropTypes.array,
  /**
   * Content to be rendered inside the layout
   */
  children: PropTypes.node.isRequired,
  /**
   * Image source
   */
  img: PropTypes.string,
  /**
   * Title of the page
   */
  pageTitle: PropTypes.string.isRequired,
};

export default LoggedInLayout;
